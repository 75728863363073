<script lang="ts" setup>
import type { GameType } from "@/types";

const props = withDefaults(
	defineProps<{
		type?: GameType;
		row?: number;
		category?: string;
		title?: string;
		tidTitle?: string;
		tidShow?: string;
		icon?: string;
		isShowAll?: boolean;
		isAdminIcon?: boolean;
		handleClickNavigate?: () => void;
	}>(),
	{ row: 1, isShowAll: true }
);

const router = useRouter();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const handleNavigate = () => {
	if (props.handleClickNavigate) {
		props.handleClickNavigate();
		return;
	}

	if (!props.category) {
		return;
	}

	router.push(`/issues/${props.category}/`);
};
</script>

<template>
	<div class="list-items-wrapper">
		<MHomeSectionHeader
			:title="title"
			:icon="icon"
			:tidTitle="tidTitle || `slider-title-${getTitle(title)}`"
			:tidShow="tidShow || `slider-${getTitle(title)}-all`"
			:is-show-all="isShowAll"
			:is-admin-icon="isAdminIcon"
			@handle-click="handleNavigate"
		/>
		<AHorizontalScroll shadowEffect>
			<div class="box-items">
				<slot />
			</div>
		</AHorizontalScroll>
	</div>
</template>
<style lang="scss" scoped>
div.list-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.box-items {
	display: grid;
	grid-auto-flow: column dense;
	gap: 12px;
	grid-template-rows: repeat(v-bind(row), 1fr);

	@include media-breakpoint-up(md) {
		gap: 8px;
	}

	&:deep(img) {
		pointer-events: none;
	}
}

@include media-breakpoint-down(md) {
	div.scroll-container {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
}
</style>
